<script setup lang="ts">
const { isDesktop } = useBreakpoint()
const { t } = useI18n()

const swiperOptions = {
  breakpoints: {
    576: {
      slidesPerView: 1.5,
    },
    768: {
      slidesPerView: 1.8,
    },
  },
  loop: true,
  slidesPerView: 1.2,
  centeredSlides: false,
  spaceBetween: 16,
}

type Service = {
  alt: string
  img: string
  label: string
  title: string
  video: string
}
const services = computed<Service[]>(() => [
  {
    img: 'production/assets/images/homepage/visuels-brand/homepage-sur-mesure-2023.webp',
    video:
      'production/assets/images/homepage/visuels-brand/homepage-sur-mesure-2023.gif',
    alt: t('global.services.alt1'),
    label: t('global.services.label1'),
    title: t('global.services.title1'),
  },
  {
    img: 'production/assets/images/homepage/visuels-brand/homepage-tranquilite-esprit-2023.webp',
    video:
      'production/assets/images/homepage/visuels-brand/homepage-tranquilite-esprit-2023.gif',
    alt: t('global.services.alt2'),
    label: t('global.services.label2'),
    title: t('global.services.title2'),
  },
  {
    img: 'production/assets/images/homepage/visuels-brand/homepage-service-local-2023.webp',
    video:
      'production/assets/images/homepage/visuels-brand/homepage-service-local-2023.gif',
    alt: t('global.services.alt3'),
    label: t('global.services.label3'),
    title: t('global.services.title3'),
  },
])
</script>

<template>
  <div class="overflow-hidden">
    <base-container class="hidden lg:block">
      <base-row>
        <base-col v-for="service in services" :key="service.img" lg="8">
          <base-service-card :service="service" />
        </base-col>
      </base-row>
    </base-container>

    <VirtualBaseCarousel
      v-if="!isDesktop"
      class-name="services-block__carousel"
      :slides="services"
      :navigation="isDesktop"
      :swiper-options="swiperOptions"
      :virtual="false"
    >
      <template #slider-content="{ slide, slideIndex }">
        <div :key="slideIndex" class="services-block__carousel-item">
          <base-service-card
            :service="slide as Service"
            class="services-block__carousel-service"
          />
        </div>
      </template>
    </VirtualBaseCarousel>
  </div>
</template>

<style>
.services-block__carousel {
  height: 493px;
  padding: 0 1rem;
}

@screen md {
  .services-block__carousel {
    height: 587px;
    padding: 0 2rem;
  }
}

@screen lg {
  .services-block__carousel {
    @apply hidden p-0;
  }
}
</style>
