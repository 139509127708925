<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import type { ApiDestination } from '~/server/types/home'

type LocalizedUrl = Record<Locale, string>
const props = withDefaults(
  defineProps<{
    destinations: Array<
      ApiDestination & { searchUrl: LocalizedUrl; housesCount: number }
    >
    page: string
    destiId?: string | null
  }>(),
  {
    destiId: null,
  },
)

const { t } = useI18n()
const { isDesktop } = useBreakpoint()

const buttonCollapseText = ref({
  open: t('house.see_less'),
  close: t('global.destinations.link'),
})
const isMoreDestiOpen = ref(false)
const filteredDestinations = computed(() =>
  props.destiId
    ? props.destinations.filter((desti) => desti.id !== props.destiId)
    : props.destinations,
)
const numberOfVisibleDesti = computed(() => (isDesktop.value ? 6 : 4))
const visibleDestinations = computed(() =>
  filteredDestinations.value.slice(0, numberOfVisibleDesti.value),
)
const hiddenDestinations = computed(() =>
  filteredDestinations.value.slice(numberOfVisibleDesti.value),
)
const showMoreDesti = computed(
  () => filteredDestinations.value.length > numberOfVisibleDesti.value,
)
</script>

<template>
  <div class="destination-block-collapse">
    <base-container>
      <general-destinations-block
        :destinations="visibleDestinations"
        :page="page"
        :desti-id="destiId"
      />
    </base-container>

    <base-container v-if="showMoreDesti">
      <base-collapse
        v-model="isMoreDestiOpen"
        :button-text="buttonCollapseText"
        color="secondary"
        content-position="up"
      >
        <general-destinations-block
          :destinations="hiddenDestinations"
          :page="page"
          :desti-id="destiId"
        />
      </base-collapse>
    </base-container>
  </div>
</template>

<style scoped>
.destination-block-collapse {
  margin: 2rem 0 0 0;
}
</style>
